@layer base {
  html {
    font-family: Roboto;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;

    @apply antialiased;
  }

  body {
    @apply bg-white;
  }

  #__next {
    @apply flex flex-col min-h-screen;
  }

  main {
    /* FIXME min-h-96 temparary? */
    @apply relative flex-1 min-h-96;
  }
}

body.ReactModal__Body--open {
  @apply overflow-hidden;
}
